<template>
    <div class="app-main">
        <top-menu />
        <router-view />
        <foot-menu />
    </div>
</template>

<script setup>
import topMenu from '@/components/top.vue';
import footMenu from '@/components/foot.vue';
import { ref, onMounted, onUnmounted, defineComponent } from 'vue'
import { useStore } from 'vuex'
const store = useStore();

defineComponent({
  components: {
    topMenu,
    footMenu
  }
})
// 创建一个新的watcher对象来监听window对象上的resize事件
const resizeHandler = () => {
    // 处理窗口大小改变后的逻辑
    if(window.innerWidth <= 1024) {
        store.commit('setPlatform', 'H5');
    } else {
        store.commit('setPlatform', 'PC');
    }
};
onMounted(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
})
onUnmounted(() => {
    window.removeEventListener('resize', resizeHandler);
})
</script>

<style lang="scss">


@media screen and (max-width: 992px) {

}
</style>
