<template>
    <div class="top-main">
        <div v-if="menuInfo.platformType === 'PC'" class="divFlex title">
          <img src="@/assets/images/logo.png" alt="">
          <div>
            <div class="middle">
              <a href="https://www.facebook.com/sharer/sharer.php?u=https://cbsrsgw.com/" target="_blank"><img class="middle" src="@/assets/images/t1.png" alt=""></a>
              <a href="https://x.com/share?url=https://cbsrsgw.com/" target="_blank"><img class="middle" src="@/assets/images/t2.png" alt=""></a>
              <a href="https://pinterest.com/pin/create/button/?url=https://cbsrsgw.com/&amp;media=https://cbsrsgw.com/wp-includes/images/media/default.svg&amp;description=Home+Boxed" target="_blank"><img class="middle" src="@/assets/images/t3.png" alt=""></a>
              <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://cbsrsgw.com/" target="_blank"><img class="middle" src="@/assets/images/t4.png" alt=""></a>
              <a href="https://telegram.me/share/url?url=https://cbsrsgw.com/" target="_blank"><img class="middle" src="@/assets/images/t5.png" alt=""></a>
            </div>
            <div class="middle" style="margin-left: 15px">
              <img class="middle cart" src="@/assets/images/cart.png" alt="">
              <span class="middle">$0</span>
            </div>
          </div>
        </div>
        <div v-if="menuInfo.platformType === 'PC'" class="menu divFlex">
          <div>
            <span :class="menuInfo.tab === 0?'active':''" @click="jumpLink('home')">HOME</span>
            <span :class="menuInfo.tab === 1?'active':''" @click="jumpLink('4')">SHOP</span>
            <span :class="menuInfo.tab === 2?'active':''" @click="jumpLink('0')">Grading S+</span>
            <span :class="menuInfo.tab === 3?'active':''" @click="jumpLink('1')">Grading S</span>
            <span :class="menuInfo.tab === 4?'active':''" @click="jumpLink('2')">Grading A+</span>
            <span :class="menuInfo.tab === 5?'active':''" @click="jumpLink('3')">Grading A</span>
          </div>
          <img src="@/assets/images/search1.png" alt="">
        </div>
        <div v-if="menuInfo.platformType === 'H5'" class="mobileMenu divFlex">
          <div @click="mobileTkShowClick">
            <img class="middle" src="@/assets/images/sort.png" alt="">
            <span class="middle">菜单</span>
          </div>
          <img class="logo" src="@/assets/images/logo.png" alt="">
          <img class="cart" src="@/assets/images/cart.png" alt="">
        </div>
        <div :class="menuInfo.mobileTkShow?'mobileMenuDialog active':'mobileMenuDialog'" @click="menuInfo.mobileTkShow = false">
          <div class="mmd-content">
            <div class="title divFlex" @click="menuInfo.mobileTkShow = false">
              <img src="@/assets/images/close.png" alt="">
              <span>关闭</span>
            </div>
            <ul class="body">
              <li :class="menuInfo.tab === 0?'active':''" @click.stop="jumpLink('home')">HOME</li>
              <li :class="menuInfo.tab === 1?'active':''" @click.stop="jumpLink('4')">SHOP</li>
              <li :class="menuInfo.tab === 2?'active':''" @click.stop="jumpLink('0')">Grading S+</li>
              <li :class="menuInfo.tab === 3?'active':''" @click.stop="jumpLink('1')">Grading S</li>
              <li :class="menuInfo.tab === 4?'active':''" @click.stop="jumpLink('2')">Grading A+</li>
              <li :class="menuInfo.tab === 5?'active':''" @click.stop="jumpLink('3')">Grading A</li>
            </ul>
          </div>
        </div>
    </div>
</template>

<script setup>
import router from '@/router'
import { ref, reactive, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
const store = useStore()

let menuInfo = reactive({
    tab: 0,
    mobileTkShow: false,
    platformType: store.state.platform,
    mobileMenuDialogShow: false
})

// 跳转  undefind 全部  0 S+  1 S  2  A+  3 A
function jumpLink(e) {
    let index;
    if(e == 'home') {
        index = 0;
        router.push('/')
    } else if(e == '0') {
        index = 2;
        router.push('/sort/0')
    } else if(e == '1') {
        index = 3;
        router.push('/sort/1')
    } else if(e == '2') {
        index = 4;
        router.push('/sort/2')
    } else if(e == '3') {
      index = 5;
      router.push('/sort/3')
    } else if(e == '4') {
      index = 1;
      router.push('/sort/4')
    }
    menuInfo.tab = index;
    store.commit('setMenuIndex', index)
    menuInfo.mobileTkShow = false
}
function mobileTkShowClick() {
    menuInfo.mobileTkShow =  true
}

watch(() => store.state.platform, () => {
  menuInfo.platformType = store.state.platform
})

onMounted(() => {
    menuInfo.platformType = store.state.platform
    setTimeout(() => {
        menuInfo.tab = store.getters.menuIndex
    }, 200)
})
</script>

<style scoped lang="scss">
.top-main{
  >.title{
    height: 104px;
    padding: 0 35px;
    border-bottom: 1px solid rgba(129, 129, 129, 0.2);
    >img{
      width: 250px;
      padding: 5px 0;
    }
    >div{
      img{
        width: 18px;
        padding: 6px;
        box-sizing: content-box;
        &.cart{
          width: 22px;
        }
      }
    }
  }
  .menu{
    height: 50px;
    padding: 0 35px;
    >div{
      span{
        position: relative;
        margin-right: 20px;
        cursor: pointer;
        color: #333;
        &::after{
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          transition: width 0.4s cubic-bezier(0.19, 1, 0.22, 1);
          width: 0;
          height: 2px;
          background-color: rgb(221,153,51);
          color: #777;
        }
        &:hover{
          color: rgba(51,51,51,0.7);
          &::after{
            width: 100%;
          }
        }
        &.active{
          color: rgba(51,51,51,0.7);
          &::after{
            width: 100%;
          }
        }
      }
    }
    img{
      width: 20px;
    }
  }
  .mobileMenu{
    padding: 0 15px;
    height: 60px;
    border-bottom: 1px solid rgba(129, 129, 129, 0.2);
    >div{
      img{
        width: 20px;
      }
      cursor: pointer;
    }
    .logo{
      width: 140px;
    }
    .cart{
      width: 20px;
    }
    span{
      font-size: 13px;
      margin-left: 10px;
      color: #333;
    }
  }
  .mobileMenuDialog{
    position: fixed;
    left: -100vw;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    z-index: 1001;
    transition: all .1s linear;
    &.active{
      left: 0;
    }
    .mmd-content{
      position: absolute;
      left: 0;
      top: 0;
      width: 300px;
      height: 100%;
      background-color: #FFFFFF;
      .title{
        height: 66px;
        padding: 0 20px;
        font-size: 110%;
        color: #333;
        justify-content: flex-end;
        cursor: pointer;
        border-bottom: 1px solid rgba(129, 129, 129, 0.2);
        img{
          width: 16px;
        }
        span{
          margin-left: 5px;
        }
      }
      .body{
        li{
          height: 50px;
          line-height: 50px;
          border-bottom: 1px solid rgba(129, 129, 129, 0.2);
          color: #333;
          padding: 0 20px;
          font-size: 13px;
          cursor: pointer;
          &.active{
            color: rgb(221,153,51);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {

}
</style>