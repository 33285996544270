<template>
    <div class="foot-main">
        <div class="divFlex fm-body">
            <div>
              Based on <strong>CC-Ginseng</strong> theme<i class="fa fa-copyright"></i> 2024 <strong>Shop Themes</strong>
            </div>
            <img src="@/assets/images/foot.png" alt="">
        </div>
        <div class="fm-foot">
          <div class="fmf-main">
            <div class="divFlex">
              <div class="list" @click="navTab(1)">
                <img src="@/assets/images/shop.png" alt="">
                <div>商店</div>
              </div>
              <div class="list" @click="navTab(2)">
                <img src="@/assets/images/home.png" alt="">
                <div>主页</div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script setup>
import {onMounted, reactive, watch} from "vue";
import {useStore} from "vuex";
import router from "@/router";

const store = useStore();
const footData = reactive({
  platformType: store.state.platform
})

const navTab = (e) => {
  let index;
  if(e === 1) {
    index = 1;
    router.push('/sort/4')
  } else {
    index = 0;
    router.push('/')
  }
  store.commit('setMenuIndex', index)
}

watch(() => store.state.platform, () => {
  footData.platformType = store.state.platform
})

onMounted(() => {
  footData.platformType = store.state.platform
})
</script>

<style scoped lang="scss">
.foot-main{
    width: 100%;
    border-top: 1px solid rgba(0,0,0,.105);
    padding: 0 35px;
    .fm-body{
        padding: 20px 0;
        span{
            font-size: 12px;
        }
        strong{
          color: #333;
        }
        img{
            height: 22px;
        }
    }
    .fm-foot{
      display: none;
      height: 55px;
      .fmf-main{
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: #FFFFFF;
        box-shadow: 0 0 9px rgba(0,0,0,0.12);
        z-index: 1000;
        >div{
          justify-content: space-around;
          height: 55px;
          .list{
            color: #333;
            text-align: center;
            cursor: pointer;
            img{
              height: 20px;
              display: block;
              margin: 0 auto;
            }
            div{
              font-size: 11px;
            }
          }
        }
      }
    }
}
@media screen and (max-width: 1024px) {
    .foot-main{
        .fm-body{
            flex-direction: column;
            img{
              margin-top: 15px;
            }
        }
        .fm-foot{
          display: block;
        }
    }
}
</style>